import axios from 'axios'
import { apiPath, configPath } from './../../config'

function supportsWebp () {
  const elem = document.createElement('canvas')
  if (elem.getContext && elem.getContext('2d')) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
  }
  return false
}

export default {
  namespaced: false,
  state: () => {
    const imageFormat = supportsWebp() ? 'webp' : 'jpeg'
    return {
      apiPath,
      applicationName: null,
      applicationId: null,
      code: null,
      countryId: null,
      basePath: null,
      assetService: null,
      seatmapService: null,
      venueService: null,
      languages: ['en'],
      imageFormat,
      pages: null,
      cookie: {
        active: null,
        profile: null
      },
      formats: {
        datetime: null,
        date: null,
        time: null,
        timer: null,
        month: null,
        dayShort: null
      },
      currency: {
        code: null,
        symbol: null,
        thousand: null,
        decimal: null,
        precision: null,
        grouping: null,
        pos: null,
        neg: null,
        zero: null
      },
      marketing: {
        active: false,
        withoutRoot: false,
        url: null,
        help: false
      },
      shopUrl: null,
      promotionCharset: null,
      feature: {},
      links: {},
      boxOfficeActivation: false,
      estimatedTicketNumbers: false
    }
  },
  mutations: {
    setConfig: (state, data) => {
      Object.assign(state, data)
      window.profile = data.countryId
    }
  },
  actions: {
    loadConfig: ({ commit }) => {
      return new Promise((resolve) => {
        axios.get(configPath).then(({ data }) => {
          commit('setConfig', data)
          resolve(data)
        })
      })
    }
  }
}
